import { Component, OnInit } from '@angular/core';
import { StepItem } from '@shared/components/tvb-steps/tvb-steps.component';
import { MetaService } from '@services/app/meta.service';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-sellers',
  templateUrl: './sellers.component.html',
  styleUrls: ['./sellers.component.scss'],
})
export class SellersComponent implements OnInit {
  public env = environment;
  constructor(private metaService: MetaService, private router: Router) {}

  ngOnInit(): void {
    //Redirect to home
    if (!this.env.SHOW_SELL_AN_ITEM) {
      this.router.navigate(['/']);
    }

    this.metaService.getStaticPageMeta('sellers', 'sell_item_meta_desc', 'Sell An Item');
  }
}
